import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/onboard',
  },
  {
    path: '/tabs',
    redirect: '/tabs/onboard',
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/onboard',
      },
      {
        path: 'onboard',
        component: () => import('@/views/OnBoard.vue'),
      },
      {
        path: 'offboard',
        component: () => import('@/views/OffBoard.vue'),
      },
      {
        path: 'onleave',
        component: () => import('@/views/LeaveBoard.vue'),
      },
      {
        path: 'log',
        component: () => import('@/views/CrewLog.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
