
import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/vue';
import {
  boatOutline,
  homeOutline,
  informationCircleOutline,
  logOutOutline,
  readerOutline,
} from 'ionicons/icons';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'TabsPage',
  components: {
    IonBadge,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    const store = useStore();
    let onboardCrew = computed(() => store.getters.onboardCrew);
    let offboardCrew = computed(() => store.getters.offboardCrew);
    let leaveCrew = computed(() => store.getters.onleaveCrew);

    return {
      boatOutline,
      logOutOutline,
      homeOutline,
      readerOutline,
      informationCircleOutline,
      onboardCrew,
      offboardCrew,
      leaveCrew,
    };
  },
});
