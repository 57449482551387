import Crew from '@/crew/crew.model';
import axios from 'axios';
import { createStore } from 'vuex';

export default createStore({
  state: {
    crew: Array<Crew>(),
  },
  mutations: {
    SET_CREW(state, crew) {
      state.crew = crew;
    },
  },
  actions: {
    async fetchCrewAsync({ commit }) {
      await axios
        .get('crew/all')
        .then((response) => {
          commit('SET_CREW', response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async toggleStatus({ commit }, { crew, status }) {
      await axios
        .post(`crew/set${status}/${crew.id}`)
        .then((response) => {
          console.log(response);
          this.dispatch('fetchCrewAsync');
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  getters: {
    onboardCrew(state) {
      return state.crew.filter((crew) => crew.status === 'onboard');
    },

    offboardCrew(state) {
      return state.crew.filter((crew) => crew.status === 'offboard');
    },

    onleaveCrew(state) {
      return state.crew.filter((crew) => crew.status === 'onleave');
    },
  },
});
